import {ActionType, getType} from 'typesafe-actions';
import {CustomerSatisfactionType} from '../shared/enums/FeedbackType';

import * as actions from './lookupActions';
import {LookupModel} from './LookupModels';

export type LookupAction = ActionType<typeof actions>;

const initialState: LookupModel = {
  templateActions: [],
  fontSizes: [],
  titleSpacings: [],
  iconSizes: [],
  iconSpacings: [],
  fontFamilies: [],
  iconSets: [],
  textSets: [],
  colorPalette: [],
  subscriptionPlans: [],
  ratingFilter: [
    { key: CustomerSatisfactionType.VerySatisfied, value: 'Very Satisfied'},
    { key: CustomerSatisfactionType.ExceededExpectations, value: 'Satisfied' },
    { key: CustomerSatisfactionType.Satisfied, value: 'Neutral' },
    { key: CustomerSatisfactionType.Dissatisfied, value: 'Unsatisfied' },
    { key: CustomerSatisfactionType.VeryDissatisfied, value: 'Very Dissatisfied' },
    { key: CustomerSatisfactionType.Archived, value: 'Archived' },
  ],
  reviewRatingFilter: [
    { key: '1', value: 1 },
    { key: '2', value: 2 },
    { key: '3', value: 3 },
    { key: '4', value: 4 },
    { key: '5', value: 5 },
  ],
  dataRangeFilter: [
    { key: "7", value: 'Last 7 Days' },
    { key: "30", value: 'Last 30 Days' },
    { key: "90", value: 'Last 90 Days' },
  ],
  reviewSiteFilter: [
    { key: 2, value: "Google" },
  ],
  pageFilter: [
    {key: "page", value: 1},
  ],
  agents: [],
  languages: [
    { key: 1 , value: "English" },
  ],
  templateLanguages: []
};

export default function lookupReducer(state: LookupModel = initialState, action: LookupAction): LookupModel {
  let newState: LookupModel;

  switch (action.type) {
    case "lookup/TEMPLATE_ACTIONS_LOADED":
      return {
        ...state,
        templateActions: action.payload
      };
    case 'lookup/FONT_SIZES_LOADED':
      return {
        ...state,
        fontSizes: action.payload,
      };
    case 'lookup/TITLE_SPACINGS_LOADED':
        return {
            ...state,
            titleSpacings: action.payload,
        };
    case 'lookup/ICON_SIZES_LOADED':
        return {
            ...state,
            iconSizes: action.payload,
        };
    case 'lookup/ICON_SPACINGS_LOADED':
        return {
            ...state,
            iconSpacings: action.payload,
        };
    case 'lookup/FONT_FAMILIES_LOADED':
      return {
          ...state,
          fontFamilies: action.payload,
      };
    case getType(actions.loadedLanguages):
      return {
        ...state,
        templateLanguages: action.payload,
      };
    case getType(actions.loadedIconSets):
      return {
        ...state,
        iconSets: action.payload,
      };
    case getType(actions.loadedTextSets):
      return {
        ...state,
        textSets: action.payload,
      };
    case getType(actions.loadedColorPalette):
      return {
        ...state,
        colorPalette: action.payload,
      };
    case getType(actions.loadedSubscriptionPlans):
      return {
        ...state,
        subscriptionPlans: action.payload,
      };
    case getType(actions.getAgentsForFilterCompleted):
      return <LookupModel>{
        ...state,
        agents: action.payload,
      };
    default:
      return state;
  }
}
