import {Box, makeStyles, Slider, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";

interface SliderInputBoxProps {
    name: string,
    label: string,
    value?: number,
    defaultValue?: number,
    onChange?: (value: number) => void,
    suffix?: string,
    step?: number | null,
    max?: number,
    min?: number,
    marks?: boolean | { value: number; label?: string }[],
}

const useStyles = makeStyles(
    (theme) => ({
        inputWidth: {
            width: "200px",
        },
    })
);

function SliderInputBox(props: SliderInputBoxProps) {
    const [value, setValue] = useState<number | number[]>(props.defaultValue ? props.defaultValue : 0);
    const classes = useStyles();
    return (
        <>
            <Typography>{props.label}</Typography>
            <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
                <Slider
                    value={value}
                    name={props.name}
                    getAriaValueText={(number) => `${number}`}
                    valueLabelFormat={(number) => `${number}${props.suffix ? props.suffix : ''}`}
                    className={`${classes.inputWidth}`}
                    aria-labelledby="discrete-slider-custom"
                    step={props.step}
                    min={props.min ? props.min : 0}
                    max={props.max ? props.max : 100}
                    valueLabelDisplay="auto"
                    onChange={(event, value) => {
                        setValue(value);
                    }}
                    onChangeCommitted={(event, value) => {
                        if (typeof  props.onChange === "function") {
                            props.onChange(Number(value));
                        }
                    }}
                    marks={props.marks ? props.marks : false}
                />
                <TextField variant={"outlined"} size={"small"} type="text" placeholder={props.suffix || ""} style={{width: "50px"}} value={value} disabled={true} />
            </Box>
        </>
    )
}

export default SliderInputBox;
