import { ApplicationState } from "../ApplicationState";
import {ActionKeyValueModel, KeyValueModel} from "../shared/SharedModels";
import { IconSetType } from "./LookupEnum";
import {TextSets} from "./LookupsApiData";

export const actionTypesSelector = (state: ApplicationState): ActionKeyValueModel[] => {
    return state.lookups.templateActions;
};

export const fontSizeTypesSelector = (state: ApplicationState): KeyValueModel[]  => {
    return state.lookups.fontSizes;
};

export const fontFamilyTypesSelector = (state: ApplicationState): KeyValueModel[]  => {
    return state.lookups.fontFamilies;
};

export const titleSpacingTypesSelector = (state: ApplicationState): KeyValueModel[]  => {
    return state.lookups.titleSpacings;
}

export const iconSizeTypesSelector = (state: ApplicationState): KeyValueModel[]  => {
    return state.lookups.iconSizes;
}

export const iconSpacingTypesSelector = (state: ApplicationState): KeyValueModel[]  => {
    return state.lookups.iconSpacings;
}

export const iconSetsSelectorByType = (state: ApplicationState, iconSetType: IconSetType): KeyValueModel[]  => {
    return state.lookups.iconSets.filter(iconSet => iconSet.value.type === iconSetType);
};

export const iconSetsSelector = (state: ApplicationState): KeyValueModel[]  => {
    return state.lookups.iconSets;
};

export const textSetsSelector = (state: ApplicationState): TextSets[] => {
    return state.lookups.textSets;
}

export const colorPaletteSelector = (state: ApplicationState): KeyValueModel[] => {
    return state.lookups.colorPalette;
}

export const subscriptionPlansSelector = (state: ApplicationState) => {
    return state.lookups.subscriptionPlans;
}

export const ratingFilterSelector = (state: ApplicationState) => {
    return state.lookups.ratingFilter;
}

export const reviewRatingFilterSelector = (state: ApplicationState) => {
    return state.lookups.reviewRatingFilter;
}

export const dataRangeFilterSelector = (state: ApplicationState) => {
    return state.lookups.dataRangeFilter;
}

export const pageFilterSelector = (state: ApplicationState) => {
    return state.lookups.dataRangeFilter;
}

export const agentsSelector = (state: ApplicationState) => {
    return state.lookups.agents;
}

export const reviewSiteSelector = (state: ApplicationState) => {
    return state.lookups.reviewSiteFilter;
}

export const languagesSelector = (state: ApplicationState) => {
    return state.lookups.languages;
};

export const templateLanguagesSelector = (state: ApplicationState) => {
    return state.lookups.templateLanguages;
};

// export const staticIconSetsSelector = (state: ApplicationState): KeyValueModel[] => {
//     return state.lookups.staticIconSets;
// }

// export const animatedIconSetsSelector = (state: ApplicationState): KeyValueModel[] => {
//     return state.lookups.animatedIconSets;
// }

