import {takeLatest, put, all, call, take, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {ActionKeyValueModel, AgentFilterModel, KeyValueModel} from '../shared/SharedModels';
import {
    FontFamilyData,
    FontSizeData,
    IconSet,
    TemplateActionData,
    TemplateColor,
    LanguageData,
    TitleSpacingData, IconSizeData, IconSpacingData, TextSets
} from "./LookupsApiData";
import * as actions from './lookupActions';
import * as setupActions from "../SetupPage/setupActions";
import * as mappers from './lookupMappers';
import * as mockApi from '../api/mockApi';
import * as api from '../api/index';

import * as lookupSelectors from './lookupSelectors';
import * as setupSelectors from '../SetupPage/setupSelectors';
import {SubscriptionPlanModel} from "../shared/SharedModels";
import {SubscriptionPlan} from "../shared/SharedData";
import {AgentProfileModel} from "../SetupPage/SetupModels";
import {AgentProfile} from "../SetupPage/SetupPageData";
import template from "../SetupPage/components/Template";

export default function* lookupSaga() {
    // implement via getType
    yield takeLatest(getType(actions.loadLanguages),loadLanguages);
    yield takeLatest('lookup/TEMPLATE_ACTIONS_LOAD', loadTemplateActionTypes);
    yield takeLatest('lookup/FONT_SIZES_LOAD', loadFontSizes);
    yield takeLatest('lookup/TITLE_SPACINGS_LOAD', loadTitleSpacings);
    yield takeLatest('lookup/ICON_SIZES_LOAD', loadIconSizes);
    yield takeLatest('lookup/ICON_SPACINGS_LOAD', loadIconSpacings);
    yield takeLatest('lookup/FONT_FAMILIES_LOAD', loadFontFamilies);
    yield takeEvery('lookup/ICON_SETS_LOAD', loadIconSets);
    yield takeEvery('lookup/TEXT_SETS_LOAD', loadTextSets)
    yield takeEvery(getType(actions.loadColorPalette), loadColorPalette);
    yield takeEvery(getType(actions.loadSubscriptionPlans), loadSubscriptionPlans);
    yield takeEvery(getType(actions.getAgentsForFilter), getAgentsForFilter);
}

export function* loadLanguages(action: ActionType<typeof actions.loadLanguages>) {

    try {
        let templateLanguages: KeyValueModel[] = yield select(lookupSelectors.templateLanguagesSelector);

        if (templateLanguages.length <= 0) {
            const data: LanguageData[] = yield call(() => api.getLanguages());

            // const data: IconSet[] = yield call(() => mockApi.getIconSets());
            templateLanguages = data.map(x => {
                return {key: x.id, value: x.name}
            });
        }
        yield put(actions.loadedLanguages(templateLanguages));

    } catch (e) {

    } finally {
        //   yield put(loadingActions.complete());
    }
}

export function* loadTemplateActionTypes() {
    // yield put(loadingActions.begin());

    try {
        let actionTypes: ActionKeyValueModel[] = yield select(lookupSelectors.actionTypesSelector);

        const response: Response = yield call(api.getTemplateActionTypes);

        if (response) {
            const templateActionTypes: TemplateActionData[] = yield call(() => response);
            // const templateActionTypes: TemplateActionData[] = yield call(mockApi.getTemplateActionTypes);
            // filter out any duplicate values
            const fiteredActionTypes = templateActionTypes.filter(
                (x, i, a) => a.findIndex(t => (t.text === x.text)) === i);

            actionTypes = fiteredActionTypes.map(x => {
                return {key: x.id, value: x.text, lang: x.lang}
            });
        }

        yield put(actions.loadedTemplateActions(actionTypes));

    } catch (e) {
        console.error(e);
    } finally {
        //   yield put(loadingActions.complete());
    }
}

export function* loadFontSizes() {
    // yield put(loadingActions.begin());

    try {
        let fontSizes: KeyValueModel[] = yield select(lookupSelectors.fontSizeTypesSelector);

        if (fontSizes.length <= 0) {
            const response: Response = yield call(api.getFontSizeTypes);

            if (response) {
                const data: FontSizeData[] = yield call(() => response);
                // const data: FontSizeData[] = yield call(mockApi.getFontSizeTypes);
                fontSizes = data.map(x => {
                    return {key: x.id, value: x.name}
                });
            }
        }
        yield put(actions.loadedFontSizes(fontSizes));

    } catch (e) {

    } finally {
        //   yield put(loadingActions.complete());
    }
}

export function* loadTitleSpacings() {
    try {
        let titleSpacings: KeyValueModel[] = yield select(lookupSelectors.titleSpacingTypesSelector);

        if (titleSpacings.length <= 0) {
            const response: Response = yield call(api.getTitleSpacingTypes);

            if (response) {
                const data: TitleSpacingData[] = yield call(() => response);
                titleSpacings = data.map(x => {
                    return {key: x.id, value: x.name}
                });
            }
        }
        yield put(actions.loadedTitleSpacings(titleSpacings));

    } catch (e) {

    } finally {

    }
}

export function* loadIconSizes() {
    try {
        let iconSizes: KeyValueModel[] = yield select(lookupSelectors.iconSizeTypesSelector);

        if (iconSizes.length <= 0) {
            const response: Response = yield call(api.getIconSizeTypes);

            if (response) {
                const data: IconSizeData[] = yield call(() => response);
                iconSizes = data.map(x => {
                    return {key: x.id, value: x.name}
                });
            }
        }
        yield put(actions.loadedIconSizes(iconSizes));

    } catch (e) {

    } finally {

    }

}

export function* loadIconSpacings() {
    try {
        let iconSpacings: KeyValueModel[] = yield select(lookupSelectors.iconSpacingTypesSelector);

        if (iconSpacings.length <= 0) {
            const response: Response = yield call(api.getIconSpacingTypes);

            if (response) {
                const data: IconSpacingData[] = yield call(() => response);
                iconSpacings = data.map(x => {
                    return {key: x.id, value: x.name}
                });
            }
        }
        yield put(actions.loadedIconSpacings(iconSpacings));

    } catch (e) {

    } finally {

    }
}

export function* loadFontFamilies() {
    // yield put(loadingActions.begin());

    try {
        let fontFamilies: KeyValueModel[] = yield select(lookupSelectors.fontFamilyTypesSelector);

        if (fontFamilies.length <= 0) {
            const response: Response = yield call(api.getFontFamilyTypes);

            if (response) {
                // const data: FontFamilyData[] = yield call(mockApi.getFontFamilyTypes);
                const data: FontFamilyData[] = yield call(() => response);
                fontFamilies = data.map(x => {
                    return {key: x.id, value: x.name}
                });
            }
        }
        yield put(actions.loadedFontFamilies(fontFamilies));

    } catch (e) {

    } finally {
        //   yield put(loadingActions.complete());
    }
}

export function* loadIconSets(action: ActionType<typeof actions.loadIconSets>) {
    // yield put(loadingActions.begin());

    try {
        let iconSets: KeyValueModel[] = [];


        if (iconSets.length <= 0) {
            const data: IconSet[] = yield call(() => api.getIconSets());
            // const data: IconSet[] = yield call(() => mockApi.getIconSets());
            iconSets = data.map(x => {
                return {
                    key: x.id,
                    value: {
                        great: x.greatIcon,
                        exceededExpectation: x.goodIcon,
                        satisfied: x.okIcon,
                        dissatisfied: x.badIcon,
                        verydissatisfied: x.terribleIcon,
                        type: x.type
                    }
                }
            });
        }
        yield put(actions.loadedIconSets(iconSets));

    } catch (e) {

    } finally {
        //   yield put(loadingActions.complete());
    }
}

export function* loadTextSets(action: ActionType<typeof actions.loadTextSets>) {
    try {
        const textSets: TextSets[] = yield call(() => api.getTextSets());

        yield put(actions.loadedTextSets(textSets))
    }

    catch(e) {
        console.log(e)
    }
}

export function* loadColorPalette(action: ActionType<typeof actions.loadColorPalette>) {
    // yield put(loadingActions.begin());

    try {
        let colorPalette: KeyValueModel[] = yield select(lookupSelectors.colorPaletteSelector);

        if (colorPalette.length <= 0) {
            const response: Response = yield call(api.getColorPalette);

            if (response) {
                const data: TemplateColor[] = yield call(() => response);
                // const data: TemplateColor[] = yield call(() => mockApi.getColorPalette());
                colorPalette = data.map(x => {
                    return {key: x.id, value: x.name}
                });
            }
        }
        yield put(actions.loadedColorPalette(colorPalette));

    } catch (e) {

    } finally {
        //   yield put(loadingActions.complete());
    }
}

export function* loadSubscriptionPlans(action: ActionType<typeof actions.loadSubscriptionPlans>) {
    // yield put(loadingActions.begin());

    try {
        const data: SubscriptionPlan[] = yield call(() => api.getSubscriptionPlans());

        let subscriptionPlans = data.map(x => mappers.subscriptionPlanToModel(x));

        yield put(actions.loadedSubscriptionPlans(subscriptionPlans));

    } catch (e) {

    } finally {
          // yield put(loadingActions.complete());
    }
}

export function* getAgentsForFilter(action: ActionType<typeof actions.getAgentsForFilter>) {
    // yield put(loadingActions.begin());

    try {
        let agentsFilterForReport: AgentProfile[] = yield select(lookupSelectors.agentsSelector);

        if (agentsFilterForReport.length <= 0) {
            agentsFilterForReport = yield call(() => api.getAgentFilterForReport());
        }

        const agentLookupSource: AgentFilterModel[] = agentsFilterForReport.map(a => {
            return {key: a.id, value: a.fullName, login: a.login}
        });
        yield put(actions.getAgentsForFilterCompleted(agentLookupSource));

    } catch (e) {

    } finally {
        //   yield put(loadingActions.complete());
    }
}