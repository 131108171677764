import React from "react";
import { Box, Typography,  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {TemplateModel, TextTemplateModel} from "../SetupModels";
import { KeyValueModel } from "../../shared/SharedModels";
import IconSetComponent from "../../shared/components/IconSetComponent";
import { IconSetModel } from "../../lookups/LookupModels";
import {TextSets} from "../../lookups/LookupsApiData";
import TextSetComponent from "../../shared/components/TextSetComponent";

type PreviewComponentProps = {
    template: TemplateModel,
    textTemplate: TextTemplateModel,
    actions: KeyValueModel[],
    fontSizes: KeyValueModel[],
    titleSpacings: KeyValueModel[],
    iconSizes: KeyValueModel[],
    iconSpacings: KeyValueModel[],
    fontFamilies: KeyValueModel[],
    colorPalette: KeyValueModel[],
    iconSets: IconSetModel[],
    templateLanguages: KeyValueModel[],
    textSets: TextSets[],
    surveySetType?: string
};

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
    },
}));

const PreviewComponent = (props: PreviewComponentProps) => {
    const classes = useStyles();
    const { action, size, font, color, iconSet, actionCount, titleSpacing, iconSpacing, iconSize} = props.template;
    const { textSets, textTemplate, surveySetType } = props;
    const chosenIconSet = props.iconSets.find(set => set.key === iconSet);
    const chosenTextSet = textSets.filter(set => set.id === textTemplate.surveySetId)

    const chosenStyles = {
        fontSize: `${size}px`,
        fontFamily: font,
        color: color
    }

    const textTemplateActionStyles = {
        fontSize: `${textTemplate.actionFontSize ? textTemplate.actionFontSize : '12'}px`
    }

    return (
        <Box className={`preview content-block ${classes.root}`}>
            <Typography style={{ ...chosenStyles }}><strong>{action}</strong></Typography>
            <Box className="icons" style={{marginTop: titleSpacing}}>
                { chosenIconSet && surveySetType === "Images" ?
                    <IconSetComponent actionCount={actionCount} icon={chosenIconSet?.value} spacing={iconSpacing} size={iconSize} /> : null
                }

                { chosenTextSet && surveySetType === "Text Only" ?
                    <table style={{...chosenStyles, ...textTemplateActionStyles, borderSpacing: `${iconSpacing ? iconSpacing/2 : 10}px 0`}}>
                        <tbody>
                        {chosenTextSet.map(set => (
                            <TextSetComponent textSet={set}/>))}
                        </tbody>
                    </table> : null}
            </Box>
        </Box>
    )
}

export default PreviewComponent;
