import {call, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {orgPlanSelector, profileSelector} from "./sharedSelectors";
import {fromUrl, parseDomain, ParseResultType} from "parse-domain";

import {getAgents} from "../SetupPage/setupActions";

import * as api from '../api/index';
import * as authApi from "../api/authenticationApi";
import * as authActions from "../auth/redux/actions";
import * as actions from './sharedActions';
import * as loadingActions from './loading/loadingStateActions';
import * as mappers from './sharedMappers';
import {
    InvitedUserProfileModel, ReCaptchaModel,
    SubscriptionPlanModel, SubscriptionTrialDaysModel,
    UserProfileModel,
    VerifyOrgRequestModel,
    WhiteLabelProfile
} from "./SharedModels";
import {Organization, SubscriptionPlan} from "./SharedData";
import {ActionType, getType} from "typesafe-actions";
import {appSettings} from "../config/appSettings";
import {getCurrentDomainName} from "./helpers";
import {addError, addServerError} from "./errors/errorActions";
import {Tina4SignInTokenResult} from "../EntryPages/SignUpPage/signUpData";

const staticDataUrl = appSettings.app.staticDataUrl;

export default function* sharedSaga() {
    yield takeLatest(getType(authActions.removeTokens), removeTokens);
    // implement via getType
    yield takeLatest(getType(actions.getWhiteLabelProfile), getWhiteLabelProfile);
    yield takeLatest(getType(actions.getProfile), getProfile);
    yield takeLatest(getType(actions.getOrganizationPlan), getSelectedPlan);
    yield takeLatest(getType(actions.getAuthUrl), getAuthUrl);
    yield takeLatest(getType(actions.getInvitedUserProfile), getInvitedUserProfile);
    yield takeLatest(getType(actions.verifyOrganization), verifyOrganization);
    yield takeLatest(getType(actions.switchOrganizationProfile), switchOrganizationProfile);
    yield takeEvery(getType(actions.verifyReCaptcha), verifyReCaptcha);
    yield takeLatest(getType(actions.getSubscriptionTrialDays), getSubscriptionTrialDays);
}

function removeTokens() {
    // clearing localstorage
    localStorage.clear();
}

function* verifyReCaptcha(action: ActionType<typeof actions.verifyReCaptcha>) {
    const token = action.payload;
    const url = 'auth/verify-recaptcha'
    const authResponseBody: Tina4SignInTokenResult = yield call(() => authApi.initialAuthToken(url));
    try {
        const response: ReCaptchaModel = yield call(() => api.verifyReCaptcha(token, authResponseBody.access_token));
        if (response.success) {
            yield put(actions.setReCaptchaVerified(true));
        } else {
            console.error(response);
        }
        yield put(actions.setReCaptchaResponse(response))
    } catch (e) {
        console.error(e);
    }
}

function* getWhiteLabelProfile(action: ActionType<typeof actions.getWhiteLabelProfile>) {
    // get the current top level domain and call the api
    let domain = getCurrentDomainName();
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    // if it contains "ratemyservice" then do nothing
    if (!domain || domain.match('ratemyservice')) {
        document.title = "Rate My Service";
        favicon.setAttribute("href",  "/rms_favicon.ico");
        yield put(actions.whiteLabelCheckCompleted())
        return;
    }

    try {
        const whiteLabelProfile: WhiteLabelProfile = yield call(() => api.getWhiteLabelProfile(domain || ""));

        if (whiteLabelProfile) {
            if (whiteLabelProfile.favicon && favicon) {
                favicon.setAttribute("href", staticDataUrl + whiteLabelProfile.favicon);
            }
            document.title = whiteLabelProfile.organisationName || "Rate My Service";
            yield put(actions.setWhiteLabelProfile(whiteLabelProfile));
            yield put(actions.whiteLabelCheckCompleted())
        } else {
            favicon.setAttribute("href",  "/rms_favicon.ico");
        }
    }
    catch (e) {
        document.title = "Rate My Service";
        console.error(e);
    }
}

function* getProfile(action: ActionType<typeof actions.getProfile>) {
    yield put(loadingActions.begin());

    try {
        const profile: UserProfileModel = yield call(api.getUserProfile);
        if (profile) {
            const organization: Organization = yield call(api.getOrganization);

            const model: UserProfileModel = {
                ...profile, organization: {
                    id: organization.id,
                    name: organization.name,
                    isTrialSubscription: organization.isTrialSubscription,
                    subscriptionStatus: organization.subscriptionStatus,
                    createdDate: organization.createdDate,
                    subscriptionStartedDate: organization.subscriptionStartedDate,
                    subscriptionPlanId: organization.subscriptionPlanId,
                    subscriptionTrialDays: organization.subscriptionTrialDays,
                    failedPaymentDays: organization.failedPaymentDays,
                    trialDaysLeft: organization.trialDaysLeft,
                    defaultEmail: organization.contactEmail,
                    defaultUserFullName: organization.contactFullName,
                    customerId: organization.customerId,
                    logoPath: organization.logoPath,
                    subscriptionIsActive: organization.subscriptionIsActive,
                    isActive: organization.isActive,
                    isVerified: organization.isVerified,
                    defaultUserId: organization.defaultUserId,
                    surveyFormIsActive: organization.surveyFormIsActive,
                    surveySetTypeId: organization.surveySetTypeId
                }
            };
            yield put(actions.loadUserProfileCompleted(model));
            let domain = getCurrentDomainName();

            // if it contains "ratemyservice" then do nothing
            if (domain && !domain.match('ratemyservice')) {
                yield put(actions.getWhiteLabelProfile());
            }
            yield put(actions.setVerifying({
                isVerified: organization.isVerified,
            }));
        }
    } catch (e) {
        console.error(e);
        yield put(loadingActions.reset());
    } finally {
        yield put(loadingActions.complete());
    }
}

function* switchOrganizationProfile(action: ActionType<typeof actions.switchOrganizationProfile>) {
    const id = action.payload;

    try {
        const profile: UserProfileModel = yield select(profileSelector);

        const organization: Organization = yield call(api.switchOrganization, id);

        const model: UserProfileModel = {
            ...profile,
            id: organization.defaultUserId,
            fullName: organization.contactFullName,
            login: organization.contactEmail,
            organizationId: organization.id,
            organization: {
                id: organization.id,
                name: organization.name,
                isTrialSubscription: organization.isTrialSubscription,
                subscriptionStatus: organization.subscriptionStatus,
                createdDate: organization.createdDate,
                subscriptionStartedDate: organization.subscriptionStartedDate,
                subscriptionPlanId: organization.subscriptionPlanId,
                subscriptionTrialDays: organization.subscriptionTrialDays,
                failedPaymentDays: organization.failedPaymentDays,
                trialDaysLeft: organization.trialDaysLeft,
                defaultEmail: organization.contactEmail,
                defaultUserFullName: organization.contactFullName,
                customerId: organization.customerId,
                logoPath: organization.logoPath,
                subscriptionIsActive: organization.subscriptionIsActive,
                isActive: organization.isActive,
                isVerified: organization.isVerified,
                defaultUserId: organization.defaultUserId,
                surveyFormIsActive: organization.surveyFormIsActive,
                surveySetTypeId: organization.surveySetTypeId
            }
        };
        yield put(actions.loadUserProfileCompleted(model));
        yield put(actions.setVerifying({
            isVerified: organization.isVerified,
        }));

        yield put(getAgents());

    } catch (e) {
        yield put(loadingActions.reset());
    } finally {
        yield put(loadingActions.complete());
    }
}

function* getInvitedUserProfile(action: ActionType<typeof actions.getInvitedUserProfile>) {
    yield put(loadingActions.begin());
    const id = action.payload;

    try {
        const response: Response = yield call(authApi.getInvitedUserProfile, id);

        if (response.ok) {
            const user: InvitedUserProfileModel = yield call(() => response.json());

            yield put(actions.getInvitedUserProfileCompleted(user));
        }
    } catch (e) {
        yield put(loadingActions.reset());
        yield put(addServerError({code: e.code, name: 'getInvitedUserProfile', message: 'Could not get invited user profile'}));
    } finally {
        yield put(loadingActions.complete());
    }
}

function* getSelectedPlan(action: ActionType<typeof actions.getOrganizationPlan>) {
    try {
        const organizationPlan: SubscriptionPlanModel = yield select(orgPlanSelector);

        if (!organizationPlan) {
            // inside function or not (for decrease duplication)
            const plan: SubscriptionPlan = yield call(api.getOrganizationPlan);
            const model = mappers.subscriptionPlanToModel(plan);
            yield put(actions.getOrgPlanCompleted(model));
        }

    } catch (e) {
        console.log("e", e);
    } finally {

    }
}

function base64ToArrayBuffer(base64: any) {
    const binaryString = window.btoa(unescape(encodeURIComponent(base64)));
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

function* getAuthUrl(action: ActionType<typeof actions.getAuthUrl>) {
    const getOAuthUrlOptions = action.payload;

    const url: string = yield call(authApi.getAuthUrl, getOAuthUrlOptions);
    yield call(() => window.location.assign(url));
}

function* verifyOrganization(action: ActionType<typeof actions.verifyOrganization>) {
    const requestBody: VerifyOrgRequestModel = action.payload;
    yield put(loadingActions.begin());

    try {

        const response: Response = yield call(() => authApi.verifyOrganization(requestBody))
        if (response.ok) yield put(actions.setIsVerified(true));

    } catch {

        yield put(loadingActions.reset());

    } finally {

        yield put(loadingActions.complete());

    }
}

function* getSubscriptionTrialDays() {
    try {
        const result: SubscriptionTrialDaysModel  = yield call(() => api.getSubscriptionTrialDays());
        if (result.trialDays) {
            yield put(actions.getSubscriptionTrialDaysCompleted(result.trialDays));
        }
    } catch(e) {
        console.error(e);
    }
}
