import { action, deprecated } from 'typesafe-actions';

import {ActionKeyValueModel, KeyValueModel} from '../shared/SharedModels';
import { IconSetType } from './LookupEnum';
import { SubscriptionPlanModel } from '../shared/SharedModels';
import {TextSets} from "./LookupsApiData";
const { createStandardAction } = deprecated;

export const loadLanguages = createStandardAction('lookup/LANGUAGES_LOAD')();
export const loadedLanguages = createStandardAction('lookup/LANGUAGES_LOADED')<KeyValueModel[]>();

export const loadTemplateActions = () => action('lookup/TEMPLATE_ACTIONS_LOAD');
export const loadedTemplateActions = (actions: ActionKeyValueModel[]) => action('lookup/TEMPLATE_ACTIONS_LOADED', actions);

export const loadFontSizes = () => action('lookup/FONT_SIZES_LOAD');
export const loadedFontSizes = (sizes: KeyValueModel[]) => action('lookup/FONT_SIZES_LOADED', sizes);

export const loadTitleSpacings = () => action('lookup/TITLE_SPACINGS_LOAD');
export const loadedTitleSpacings = (spacings: KeyValueModel[]) => action('lookup/TITLE_SPACINGS_LOADED', spacings);

export const loadIconSizes = () => action('lookup/ICON_SIZES_LOAD');
export const loadedIconSizes = (sizes: KeyValueModel[]) => action('lookup/ICON_SIZES_LOADED', sizes);

export const loadIconSpacings = () => action('lookup/ICON_SPACINGS_LOAD');
export const loadedIconSpacings = (spacings: KeyValueModel[]) => action('lookup/ICON_SPACINGS_LOADED', spacings);

export const loadFontFamilies = () => action('lookup/FONT_FAMILIES_LOAD');
export const loadedFontFamilies = (families: KeyValueModel[]) => action('lookup/FONT_FAMILIES_LOADED', families);

export const loadIconSets = createStandardAction('lookup/ICON_SETS_LOAD')();
export const loadedIconSets = createStandardAction('lookup/ICON_SETS_LOADED')<KeyValueModel[]>();

export const loadTextSets = createStandardAction('lookup/TEXT_SETS_LOAD')();
export const loadedTextSets = createStandardAction('lookup/TEXT_SETS_LOADED')<TextSets[]>();

export const loadColorPalette = createStandardAction('lookup/COLOR_PALETTE_LOAD')();
export const loadedColorPalette = createStandardAction('lookup/COLOR_PALETTE_LOAD_COMLETED')<KeyValueModel[]>();

export const loadSubscriptionPlans = createStandardAction('lookup/SUBSCRIPTION_PLAN_LOAD')();
export const loadedSubscriptionPlans = createStandardAction('lookup/SUBSCRIPTION_PLAN_LOAD_COMLETED')<SubscriptionPlanModel[]>();

export const getAgentsForFilter = createStandardAction('lookup/GET_AGENTS_FOR_FILTER')();
export const getAgentsForFilterCompleted = createStandardAction('lookup/GET_AGENTS_FOR_FILTER_COMPLETED')<KeyValueModel[]>();
