import {TextSets} from "../../lookups/LookupsApiData";

interface TextSetComponentProps {
    textSet: TextSets
}

const TextSetComponent = ({textSet}: TextSetComponentProps) => {
    return (
            <tr dangerouslySetInnerHTML={{__html: textSet.snippet}}></tr>
    )
}

export default TextSetComponent;