import { getType } from 'typesafe-actions';
import { switchNavMenu } from './sharedActions';
import { GlobalsModel } from './SharedModels';
import { SharedState } from './sharedState';
import * as actions from "./sharedActions";
import { toggleSurveyFormIsActiveCompleted, updateSurveySetType } from "../SetupPage/setupActions";

const initialSharedState: GlobalsModel = {
    navTabIsSelected: true,
    isSearching: false,
    isDisabledService: false,
    verifying: {
        isVerified: false,
    },
    passwordReset: {

    },
    reCaptchaVerified: false,
    reCaptchaResponse: {
        success: false,
        action: '',
        challenge_ts: '',
        hostname: '',
        score: 0,
    },
    subscriptionTrialDays: 0,
    whiteLabel: undefined,
    whiteLabelCheckCompleted: false,
}

const sharedReducer = (state: GlobalsModel = initialSharedState, action: any) => {

    switch(action.type) {
        // implement via getType
        case 'shared/SWITCH_NAV_MENU':
            return {
                ...state,
                navTabIsSelected: action.payload,
            }
        case 'shared/LOAD_PROFILE_COMPLETED':
            return {
                ...state,
                profile: action.payload,
            }
        case 'shared/GET_ORGANIZATION_PLAN_COMPLETED':
            return {
                ...state,
                orgPlan: {
                    ...action.payload,
                },
            }
        case (getType(actions.setIsSearching)):
            return {
                ...state,
                isSearching: action.payload,
            }
        case (getType(actions.setServiceIsDisabledFlag)):
            return {
                ...state,
                isDisabledService: action.payload,
            }
        case getType(actions.getInvitedUserProfileCompleted):
            return {
                ...state,
                invitedUserProfile: action.payload,
            }
        case getType(actions.setIsVerified):
            return {
                ...state,
                profile: {
                  ...state.profile, organization: {
                    ...state.profile?.organization,
                    isVerified: action.payload,
                  }
                },
                verifying: {
                    ...state.verifying,
                    isVerified: action.payload,
                },
            }
        case getType(actions.setVerifying):
            return {
                ...state,
                profile: {
                    ...state.profile, organization: {
                        ...state.profile?.organization,
                        isVerified: action.payload.isVerified,
                    }
                },
                verifying: {
                    ...state.verifying,
                    isExpired: action.payload.isExpired,
                    isVerified: action.payload.isVerified,
                },
            }
        case getType(actions.setReenteringFlag):
            return {
                ...state,
                verifying: {
                    ...state.verifying,
                    isReentering: action.payload,
                },
            }
        case getType(actions.setForgotPasswordConfirmed):
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    isForgotConfirmed: action.payload,
                },
            }
        case getType(actions.setResetPasswordConfirmed):
            return {
                ...state,
                passwordReset: {
                    ...state.passwordReset,
                    isResetConfirmed: action.payload,
                },
            }
        case getType(actions.clearUserProfile):
            return {
                ...state,
                profile: undefined,
            }
        case getType(toggleSurveyFormIsActiveCompleted):
            return {
                ...state,
                profile: {
                    ...state.profile,
                    organization: {
                        ...state.profile?.organization,
                        surveyFormIsActive: action.payload,
                    }
                }
            }
        case getType(actions.setWhiteLabelProfile):
            return {
                ...state,
                whiteLabel: action.payload,
            }
        case getType(actions.whiteLabelCheckCompleted):
            return {
                ...state,
                whiteLabelCheckCompleted: true
            }
        case getType(actions.setReCaptchaVerified):
            return {
                ...state,
                reCaptchaVerified: action.payload,
            }
        case getType(actions.setReCaptchaResponse):
            return {
                ...state,
                reCaptchaResponse: action.payload,
            }
        case getType(actions.getSubscriptionTrialDaysCompleted):
            return {
                ...state,
                subscriptionTrialDays: action.payload,
            }
        case getType(updateSurveySetType): {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    organization: {
                        ...state.profile?.organization,
                        surveySetTypeId: action.payload,
                    }
                }
            }
        }
        default:
            return {
                ...state,
            };
    }
}

export default sharedReducer;
